.contacts_box {

	@extend %block;

	&:last-child {
		padding-bottom: 0;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		width: 590px;
		background: url(../images/bg_contacts.svg) 100% 0 no-repeat;
		z-index: 1;
	}

	.fixed {
		z-index: 2;
	}

	.wrapper {
		@extend %narrow;

		margin-bottom: $margin_desktop;

	}

	.heading {
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
		color: rgba($color_main, .4);
		margin-bottom: 10px;
	}

	.title {
		letter-spacing: 0.8px;
		font-size: 30px;
		line-height: 1.33;
		font-weight: bold;
		font-family: $font_playfair;
		margin-bottom: 30px;
	}

	.time {
		margin-bottom: 6px;
	}

	.date {
		font-size: 22px;
		margin-bottom: 30px;
	}

	.button {
		display: inline-block;
		position: relative;
		cursor: pointer;
		padding: 10px 10px 0 0;
		margin-top: 40px;
		text-align: center;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 10px;
			bottom: 10px;
			right: 0;
			top: 0;
			border: 1px solid rgba($color_gray, 0.4);
		}

		span {
			position: relative;
			z-index: 2;
			display: inline-block;
			padding: 18px 40px;
			box-sizing: border-box;
			background-color: $color_gray;
			font-family: $font_playfair;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 1.25;
			text-align: center;
			letter-spacing: 2px;
			text-transform: uppercase;
			transition: 0.3s;
		}

		&:hover {

			span {
				background-color: #e2c2b5;
			}

		}

	}

	.contact {
		display: flex;
		justify-content: flex-end;
		padding: 80px;
		background-size: cover;
		position: relative;
		overflow: hidden;
	}

	.background {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: url(../images/bg_contact.jpg) 50% 50% no-repeat;
		background-size: cover;
		z-index: 1;
		animation-name: hero;

		&.animated {
			animation-duration: 1s;
		}

	}

	.inner {
		padding: 80px;
		width: 270px;
		background-color: $background_pink;
		position: relative;
		z-index: 2;

		.button {

			&:before {
				border-color: rgba($color_pink, .4);
			}

			span {
				min-width: 190px;
				background-color: $color_pink;
			}

			&:hover {

				span {
					background-color: $color_gray;
				}

			}

		}

	}


	.heading, .title, .time, .date, .button {
		animation-name: fadeInUp;
	}

	.title {
		animation-delay: 0.25s;
	}

	.time {
		animation-delay: 0.5s;
	}

	.date {
		animation-delay: 0.75s;
	}

	.button {
		animation-delay: 1s;
	}

	@include desktop_small {

		.wrapper {
			margin-bottom: $margin_tablet;
		}

		.title {
			margin-bottom: 25px;
		}

		.button {
			margin-top: 0;
		}

		.contact {
			padding: 40px;
			margin-left: -20px;
			margin-right: -20px;

			&:before {
				background-position: 10% 50%;
			}

		}

		.inner {
			padding: 40px;
		}

	}

	@include tablet_large {

		.wrapper {
			margin-bottom: 40px;
		}

		.contact {
			flex-direction: column;
			align-items: center;
			padding: 0;

			&:before {
				position: relative;
				top: auto;
				right: auto;
				bottom: auto;
				left: auto;
				height: 300px;
				width: 100%;
				background-position: 0 50%;
				background-size: cover;
			}

		}

		.inner {
			width: calc(100% + 40px);
			box-sizing: border-box;
			padding: 40px 20px;
			margin: 0 -20px;
			text-align: center;

			.heading {
				margin-bottom: 10px;
			}

			.title {
				margin-bottom: 10px;

				br {
					display: none;
				}

			}

		}

	}

	@include phone_large {

		.title {
			font-size: 26px;
		}

		.date {
			font-size: 20px;
		}

	}

}