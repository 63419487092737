.hero_box {
	@extend %block;
	@extend %hero;
	display: flex;
	align-content: flex-end;
	align-items: flex-end;
	position: relative;
	overflow: hidden;
	height: calc(100vh - 100px);
	max-height: 1000px;
	padding: 60px 0;
	background-color: #000000;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, rgba(39, 39, 39, 0) 50%, rgba(39, 39, 39, 0.2) 100%);
		z-index: 2;
	}

	.background {
		display: block;
		position: absolute;
		background: url(../images/bg_hero.jpg) 50% 50% no-repeat;
		background-size: cover;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		will-change: transform;
		transform: translate3d(0, 0, 0);
		transition: none;
	}

	.fixed {
		z-index: 3;
	}

	.wrapper {
		display: block;
		position: relative;
		width: 50%;
		margin-bottom: 15vh;
		padding-top: 50px;
		color: $color_white;
	}

	.heading {
		display: flex;
		align-items: center;
		margin-bottom: 25px;
		font-size: 12px;
		letter-spacing: 1px;
		text-transform: uppercase;
	}

	.rings {
		display: block;
		position: relative;
		width: 13px;
		height: 13px;
		margin-right: 30px;
		border: 1px solid;
		border-radius: 50%;
		z-index: 2;

		&:before {
			content: '';
			position: absolute;
			top: -1px;
			left: 9px;
			width: 13px;
			height: 13px;
			border: 1px solid;
			border-radius: 50%;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			right: 100%;
			width: 80px;
			margin-right: 15px;
			border-top: 1px solid;
			opacity: 0.6;
		}

	}

	h1 {
		display: inline-block;
		position: relative;
		padding-right: 80px;
		font-family: $font_playfair;
		font-size: 52px;
		font-weight: 700;
		line-height: 1.1;
		letter-spacing: 1.67px;

		span {
			display: block;
			position: relative;
			margin-bottom: 12px;
			z-index: 2;
		}

	}

	.amp {
		content: '&';
		display: block;
		position: absolute;
		right: 0;
		bottom: -60px;
		font-family: $font_baskerville;
		font-size: 300px;
		font-weight: 400;
		letter-spacing: 0;
		color: rgba(#fbe0d7, 0.5);
		z-index: 1;
	}

	/* Animation */

	.background {

		&.animated {
			animation-name: hero;
			animation-duration: 3s;
		}

	}
	
	.gwyn {
		animation-delay: 1s;
		animation-name: fadeInRight;
	}

	.alice {
		animation-name: fadeInLeft;
		animation-delay: 1.5s;
	}

	.amp {
		animation-name: fadeIn;
		animation-delay: 2s;
	}

	.heading {
		animation-name: fadeIn;
		animation-delay: 2.5s;
	}

	@include desktop_large {
		align-items: flex-start;
		align-content: flex-start;

		&:after {
			background: linear-gradient(0deg, rgba(39, 39, 39, 0) 60%, rgba(39, 39, 39, 0.4) 100%);
		}

		.background {
			background-position: 80% 50%;
		}

		.wrapper {
			margin-bottom: 0;
			padding-top: 0;
			width: auto;
		}

		.heading {
			margin-bottom: 15px;
		}

		.rings {

			&:after {
				display: none;
			}

		}

		.amp {
			font-size: 200px;
			bottom: -40px;
		}

		h1 {
			font-size: 46px;
			padding-right: 60px;

			span {
				margin-bottom: 7px;
			}

		}

	}

	@include desktop_small {
		height: calc(100vh - 80px);

		& + .has_background {
			margin-top: -40px;
		}

		h1 {
			font-size: 38px;
			padding-right: 40px;
		}

		.amp {
			font-size: 150px;
			bottom: -30px;
		}

	}

	@include tablet_small {
		height: calc(100vh - 60px);

		.background {
			background-position: 70% 0;
		}

	}

	@include phone_small {

		.heading {
			margin-bottom: 10px;
		}

		h1 {
			font-size: 32px;
			padding-right: 10px;

			span {
				line-height: 1.25;
				margin-bottom: 0;
			}

		}

		.amp {
			font-size: 100px;
			bottom: -20px;
		}

	}

}