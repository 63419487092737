@import 'includes/sprite.svg';

@import 'includes/sprite.png';

@import 'includes/variables';

@import 'includes/mixins';

@import 'base/reset';

@import 'base/fonts';

@import 'base/defaults';

@import 'base/global';

@import 'base/header';

@import "blocks/about.scss";
@import "blocks/comments.scss";
@import "blocks/contacts.scss";
@import "blocks/content.scss";
@import "blocks/event.scss";
@import "blocks/flickity.scss";
@import "blocks/flight.scss";
@import "blocks/heading.scss";
@import "blocks/hero.scss";
@import "blocks/hotels.scss";
@import "blocks/location.scss";
@import "blocks/modal.scss";
@import "blocks/pagination.scss";
@import "blocks/preloader.scss";
@import "blocks/slider.scss";
@import "blocks/widget.scss";

@import 'base/footer';

@import 'base/animation';