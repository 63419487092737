.flight_box {

	@extend %block;

	.wrapper {
		@extend %narrow
	}

	.wrapper {
		@extend %narrow
	}

	.category {
		color: #dda896;

		@extend %category;

	}

	.heading_box {

		.number {
			color: rgba(#fbe0d7, 0.6);
		}

	}

	.contents {
		column-count: 2;
		column-gap: 100px;
		animation-name: fadeInUp;
		animation-delay: 0.75s;

		p {
			margin-bottom: 30px;
		}

	}

	@include desktop_large {

		.contents {
			column-gap: 60px;
		}

	}

	@include desktop_small {

		.heading {

			br {
				display: none;
			}

		}

	}

	@include tablet_large {

		.contents {
			column-count: 1;
			column-gap: 0;
		}

	}

	@include phone_large {

		.contents {

			p {
				margin-bottom: 15px;
			}

		}

	}

}