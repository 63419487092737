.heading_box {
	display: block;
	position: relative;
	margin-bottom: 25px;

	.number {
		color: rgba(#FBE0D7, .4);
		font-family: $font_baskerville;
		font-size: 100px;
		line-height: 80px;
		font-weight: 400;
		position: relative;
		left: -7px;
		animation-name: fadeInLeft;
	}

	.caption {
		color: rgba($color_main, .4);
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;
		position: absolute;
		top: 40px;
		left: 5px;
		animation-delay: 0.25s;
		animation-name: fadeInLeft;
	}

	.title {
		font-family: $font_playfair;
		font-size: 30px;
		font-weight: 700;
		letter-spacing: 0.8px;
		margin-bottom: 0;
		animation-name: fadeInLeft;
		animation-delay: 0.5s;
	}

	&.inverted {

		.number, .caption, .title {
			animation-name: fadeInRight;
		}

	}

	@include tablet_large {
		text-align: center;

		.number {
			left: 0;
		}

		.caption {
			display: block;
			left: 0;
			width: 100%;
			text-align: center;
		}

		.title {

			br {
				display: none;
			}

		}

	}

	@include phone_large {

		.number {
			font-size: 90px;
			line-height: 70px;
		}

		.title {
			font-size: 26px;
		}

	}

}