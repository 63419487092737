.header_box {
	display: flex;
	align-items: center;
	height: 100px;
	box-sizing: border-box;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	background-color: $color_white;
	z-index: 10;
	transform: translateY(0);
	transition: 0.3s;

	&.is_hidden {
		transform: translateY(-100px);
	}

	&.is_scroll {
		height: 60px;
		box-shadow: 0 0 5px rgba(#000, 0.15);

		&.is_hidden {
			transform: translateY(-60px);
		}

	}

	.menu {
		display: flex;
		align-items: center;
		text-align: center;

		li {
			display: block;
			font-size: 12px;
			line-height: 1.25;
			margin-right: 50px;
			text-transform: uppercase;

			&:last-child {
				margin-right: 0;
			}

			&.active > a, & > a:hover {
				text-shadow: 0 0 1px $color_main;
			}

			a {
			}

		}

		.featured a {
			display: inline;
			position: relative;
			padding: 0 15px;
			color: #ebbaaa;

			&:before, &:after {
				content: '';
				display: block;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: #dda896;
				position: absolute;
				top: 50%;
				left: 0;
				margin-top: -3px;
			}

			&:after {
				left: auto;
				right: 0;
			}

			&:hover {
				text-shadow: 0 0 1px #dda896;
				color: #dda896;
			}

		}

	}

	@include desktop_small {
		height: 80px;

		&.is_hidden {
			transform: translateY(-80px);
		}

		.menu {
			justify-content: center;
		}

	}

	@include tablet_small {
		height: 60px;

		&.is_hidden {
			transform: translateY(-60px);
		}

		.menu {
			justify-content: space-around;

			li {
				margin-right: 10px;
			}

			.featured {

				a {
					padding: 0 10px;
				}

			}

		}

	}

	@include phone_large {

		.menu {
			justify-content: space-between;

			li {
				margin-right: 5px;

				a {
					font-size: 13px;
					text-transform: none;
				}

			}

			.featured {

				a {
					font-size: 12px;
					padding: 0;

					&:before, &:after {
						display: none;
					}

				}

			}

		}

	}

}