#site {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	min-height: 100%;
	z-index: 2;
}

.container_box {
	display: block;
	flex-grow: 1;
	flex-shrink: 0;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 100px;

	@include desktop_small {
		padding-top: 80px;
	}

	@include tablet_small {
		padding-top: 60px;
	}

}

.wrapper_box {
	display: block;
	position: relative;

	.fixed {
		display: flex;
		align-items: flex-start;
		flex-direction: row;
		flex-wrap: nowrap;
	}

	.main_box {
		display: block;
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
		position: relative;
		min-width: 0;
		order: 1;
	}

	.sidebar_box {
		display: block;
		flex-grow: 0;
		flex-shrink: 0;
		width: 270px;
		margin-left: 30px;
		order: 2;
	}

	@include desktop_small {

		.sidebar_box {
			margin-left: 20px;
		}

	}

	@include tablet_large {
		flex-wrap: wrap;
		justify-content: center;

		.main_box {
			width: 100%;
			order: 1;
		}

		.sidebar_box {
			margin-left: 0;
			width: 100%;
			max-width: 320px;
			order: 2;
		}

	}

}

.fixed {
	box-sizing: border-box;
	position: relative;
	width: 100%;
	max-width: 1010 + 30*2 + px;
	margin: 0 auto;
	padding: 0 30px;
	z-index: 2;

	.fixed {
		padding: 0;
	}

	@include desktop_small {
		padding: 0 20px;
	}

}

.rsvp {
	display: block;
	box-sizing: border-box;
	position: fixed;
	top: 50%;
	right: 0;
	min-width: 160px;
	margin-top: -120px;
	padding: 20px;
	background-color: $color_white;
	border: 1px solid;
	border-bottom: none;
	font-family: $font_playfair;
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: 4px;
	color: #E8B4A3;
	text-align: center;
	text-transform: uppercase;
	transform: rotate(-90deg);
	cursor: pointer;
	z-index: 8;
	transform-origin: 100% 100%;

	&:hover {
		color: #ff7c4f;
	}

	@include desktop_large {
		padding: 10px 20px;
		min-width: 120px;
		font-size: 14px;
	}

}

%block {
	display: block;
	position: relative;
	box-sizing: border-box;
	margin-bottom: $margin_desktop;
	z-index: 1;

	.header_box + & {
		margin-top: ($margin_desktop - 40px);
	}

	@include desktop_small {
		margin-bottom: $margin_tablet;

		.header_box + & {
			margin-bottom: ($margin_tablet - 20px);
		}

	}

	@include phone_large {
		margin-bottom: $margin_mobile;

		.header_box + & {
			margin-top: $margin_mobile;
		}

	}

}

%hero {

	.header_box + & {
		margin-top: 0;
	}


}

%narrow {
	padding-left: 80px;
	padding-right: 80px;
	position: relative;

	@include desktop_small {
		padding-left: 0;
		padding-right: 0;
	}

}

%category {
	position: absolute;
	top: -#{$margin_desktop};
	left: 0;
	transform: translateX(-100%) rotate(-90deg);
	transform-origin: 100% 0;
	font-family: $font_playfair;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0.7px;
	white-space: nowrap;
	animation-name: fadeIn;
	animation-delay: 1s;

	&:after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 100px;
		border-bottom: 1px solid;
		margin-left: 20px;
	}

	@include desktop_large {
		top: -#{$margin_tablet};
		left: 10px;
	}

	@include desktop_small {
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		margin-bottom: 50px;
		max-width: 100%;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;

		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 100px;
			border-bottom: 1px solid;
			margin-right: 20px;
		}

		&:before, &:after {
			position: relative;
			top: 1px;
		}

	}

	@include phone_large {
		margin-bottom: 30px;
	}

}

.background_gray {
	background-color: $background_gray;

	.heading {

		.number {
			color: rgba($color_gray, 0.3);
		}

	}
}

.background_pink {
	background-color: $background_pink;
}

.has_background {
	margin-bottom: $margin_desktop;
	padding: $margin_desktop 0;

	&:last-child {
		margin-bottom: 0;
	}

	.header_box + & {
		margin-top: 0;
	}

	.has_background + & {
		margin-top: -$margin_desktop;
	}

	@include desktop_large {
		padding: $margin_tablet 0;
	}

	@include desktop_small {
		margin-bottom: $margin_tablet;

		.has_background + & {
			margin-top: -$margin_tablet;
		}

	}

	@include phone_large {
		padding: $margin_mobile 0;
		margin-bottom: $margin_mobile;

		.has_background + & {
			margin-top: -$margin_mobile;
		}

	}

}