.about_box {
	z-index: 2;

	@extend %block;

	&.background_gray {
		margin-top: 170px;

		.category {
			color: $color_gray;
		}

		.image {
			margin-top: -230px;
		}

	}

	.wrapper, .items {
		@extend %narrow;
	}

	.category {
		@extend %category;
	}

	.contents {
		display: flex;
	}

	.image {
		display: block;
		position: relative;
		flex-shrink: 0;
		flex-grow: 0;
		width: 50%;
		margin-right: 80px;
		will-change: transform;
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			height: auto;
			animation-name: hero;

			&.animated {
				animation-duration: 1s;
			}

		}

		&:nth-child(2){
			margin-right: 0;
			margin-left: 80px;
		}

	}


	.text {
		animation-name: fadeInLeft;
		animation-delay: 0.75s;
		line-height: 2;

		& > :last-child {
			margin-bottom: 0;
		}

	}

	&.has_background {
		.text {
			animation-name: fadeInRight;
		}
	}

	@include desktop_large {

		&.background_gray {

			.image {
				margin-top: -150px;
			}

		}

	}

	@include desktop_small {

		&.background_gray {
			margin-top: auto;

			.image {
				margin-top: 30px;
			}

		}

		.wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.contents {
			align-items: center;
		}

		.image {
			margin-right: 40px;

			&:nth-child(2) {
				margin-right: 0;
				margin-left: 40px;
			}

		}

	}

	@include tablet_large {

		.contents {
			flex-direction: column;
		}

		.image, .image:nth-child(n) {
			width: auto;
			order: 1;
			margin: 30px -20px #{-$margin_tablet};
		}

	}

	@include phone_large {

		.image, .image:nth-child(n) {
			margin-bottom: -$margin_mobile;
		}

	}

}