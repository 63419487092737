@keyframes hero {
	from {
		filter: blur(15px);
		opacity: 0;
	}
	to {
		opacity: 1;
		filter: blur(0);
	}
}

@keyframes background {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: -1366px 0;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100px, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -100px, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translate3d(-100px, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(100px, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes zoomIn {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
	50% {
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.2;
	}
}

@keyframes welcome {
	0% {
		opacity: 0;
	}
	33% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


.animated {
	animation-duration: 1.5s;
	animation-fill-mode: both;
}