html, body {
	height: 100%;
}

body {
	min-width: 320px;
	font-family: $font_main;
	font-size: 16px;
	line-height: 1.5;
	color: $color_main;

	&.locked {
		overflow: hidden;
	}

}

h1, h2, h3, h4, h5, h6 {
	display: block;
	position: relative;
	margin: 0 0 20px;
	font-family: $font_main;
	font-weight: 700;
	line-height: 1.25;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 26px;
}

h4 {
	font-size: 22px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

@include desktop_small {
	h1 {
		font-size: 32px;
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 20px;
	}

	h4 {
		font-size: 18px;
	}
}

ol, ul {
	list-style-position: inside;
}

p {
	margin: 0 0 20px;
}

a {
	color: $color_main;
	text-decoration: none;
	transition: 0.2s;

	img {
		transition: opacity 0.2s;
	}

	&:hover {
		text-decoration: none;

		img {
			opacity: 0.8;
		}
	}
}

input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="submit"], textarea {
	display: block;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	padding: 15px 20px;
	background: $color_white;
	border: 1px solid rgba($color_main, 0.2);
	font-family: $font_main;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.25;
	transition: 0.2s;
	z-index: 2;
	-webkit-appearance: none;

	&:focus {
		border-color: rgba($color_main, 0.4);
	}
}

textarea {
	overflow: auto;
	height: 200px;
}

input[type="submit"] {
	font-weight: 700;
	color: $color_white;
	background-color: $color_main;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		background-color: lighten($color_main, 20%);
	}
}
