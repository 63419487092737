.location_box {

	@extend %block;

	background: linear-gradient(to bottom, #fdf3ef, #fdede8);

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 50px;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background: url(../images/bg_location.svg) 0 0 repeat-x;
		background-size: 1366px 173px;
		animation-name: background;
		animation-duration: 40s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	.fixed {
		z-index: 2;
	}

	.wrapper {
		@extend %narrow;
	}

	.title {
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
		color: rgba($color_main, .4);
		margin-bottom: 20px;
	}

	.date, .location {
		letter-spacing: 0.8px;
		font-size: 30px;
		line-height: 1.33;
		font-family: $font_playfair;
	}

	.date {
		font-weight: bold;
		margin-bottom: 10px;
	}

	.category {
		color: #dda896;
		top: 0;

		@extend %category;
	}

	.title {
		animation-name: fadeInUp;
	}

	.date {
		animation-name: fadeInUp;
		animation-delay: 0.25s;
	}

	.location {
		animation-name: fadeInUp;
		animation-delay: 0.5s;
	}

	@include desktop_large {

		&:before {
			background-size: 100% auto;
		}

	}

	@include desktop_small {

		.wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.category {
			margin-bottom: 30px;
		}

	}

	@include phone_large {

		.title {
			margin-bottom: 10px;
		}

		.date, .location {
			font-size: 26px;
		}

		.date {
			margin-bottom: 0;
		}

		.category {
			margin-bottom: 20px;
		}

	}

}
