$font_main: 'Lato', sans-serif;
$font_baskerville: 'Libre Baskerville', serif;;
$font_playfair: 'Playfair Display', serif;;

$color_main: #272727;
$color_white: #ffffff;
$color_gray: #c7cfc4;
$color_pink: #dda896;
$color_background: #f4f5f3;

$background_pink: #fef4f1;
$background_gray: #f4f5f3;

$margin_content: 25px;

$margin_desktop: 100px;
$margin_tablet: 60px;
$margin_mobile: 40px;

$gap_large: 40px;
$gap_normal: 30px;
$gap_small: 20px;

$desktop_large: 1170 + 30 * 2;
$desktop_small: 1024;

$tablet_large: 768;
$tablet_small: 640;

$phone_large: 480;
$phone_small: 420;