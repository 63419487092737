.preloader_box {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #151b23;
	transition: 0.5s;
	z-index: 10;

	&.hidden {
		visibility: hidden;
		opacity: 0;
		z-index: 1;
	}

	.wrapper {
		display: block;
		position: relative;
		font-size: 52px;
		font-family: 'Times New Roman', serif;
		font-weight: 700;
		line-height: 1.25;
		color: $color_white;
		text-align: center;
		animation-name: welcome;
		animation-duration: 3s;
		animation-iteration-count: 1;

		span {
			display: block;
			position: relative;
			z-index: 2;
		}

	}

	.and {
		display: block;
		position: absolute;
		z-index: 1;
		left: 50%;
		top: 50%;
		font-size: 300px;
		font-weight: 400;
		line-height: 1;
		opacity: 0.1;
		transform: translate(-50%, -50%);
		animation-name: pulse;
		animation-duration: 2s;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@include desktop_large {
		font-size: 46px;
	}

	@include desktop_small {
		font-size: 38px;
	}

	@include phone_small {
		font-size: 32px;
	}

}