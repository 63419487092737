.slider_box {

	@extend %block;

	padding-top: 50px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 50%;
		background: linear-gradient(to bottom, #fdede8, #fceae3);
		z-index: 1;
	}

	.location_box + & {
		padding-top: 50px;
		margin-top: -$margin_desktop;
	}

	.fixed {
		z-index: 2;
	}

	.slides {
		display: block;
		position: relative;
		max-width: 798px;
		margin: 0 auto;
	}

	.background {
		display: block;
		width: 250px;
		height: 350px;
		border: 1px solid #C7CFC4;
		position: absolute;
		top: -275px;
		right: 25px;
		z-index: 1;
	}

	.slider {
		display: block;
		position: relative;
		font-size: 0;
		line-height: 0;
		white-space: nowrap;
		overflow: hidden;
		z-index: 2;
	}

	.slide {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		overflow: hidden;
		width: 100%;

		img {
			display: block;
			width: 100%;
			height: auto;
			animation-name: hero;

			&.animated {
				animation-duration: 1s;
			}

		}

	}

	.navigation {
		display: flex;
		justify-content: flex-end;
		position: relative;
		z-index: 1;
	}

	.arrow_left, .arrow_right {
		display: block;
		width: 60px;
		height: 50px;
		background: $color_main url(../images/ico_arrow.svg) 50% 50% no-repeat;
		cursor: pointer;
		transition: 0.3s;

		&:hover {
			opacity: 0.8;
		}

	}

	.arrow_right {
		transform: rotate(180deg);
	}

	/* Animation */

	.navigation {
		animation-name: fadeInDown;
	}

	@include desktop_large {

		.slides {

			&:before {
				width: 210px;
				height: 300px;
				top: -225px;
				right: 50px;
			}

		}

	}

	@include desktop_small {

		.location_box + &{
			margin-top: -$margin_tablet;
			padding-top: 0;
		}

		.background {
			display: none;
		}

		.navigation {
			justify-content: center;
		}

		.fixed {
			padding-left: 0;
			padding-right: 0;
		}

	}

	@include tablet_small {

		.arrow_left, .arrow_right {
			width: 40px;
			height: 35px;
		}

	}

	@include phone_large {

		.location_box + & {
			margin-top: -$margin_mobile;
		}

	}

}