.pagination_box {

	@extend %block;

	text-align: center;

	span, a {
		display: inline-block;
		margin: 0 3px;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.25;
		padding: 2px 6px;
		text-decoration: none;
		vertical-align: middle;
	}

	span {
		background-color: $color_background;
		color: $color_main;
	}

	a:hover {
		color: #0d7b0d;
	}

	.extend {
		background: transparent;
	}

	span.next, span.prev {
		background: transparent;
		color: #0d7b0d;
	}

}