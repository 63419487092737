.hotels_box {

	@extend %block;

	.wrapper {

		@extend %narrow;

		display: flex;
		align-items: center;
		margin-bottom: $gap_large;
	}

	.heading_box {
		width: 50%;
		flex-grow: 0;
		flex-shrink: 0;

		.number {
			color: rgba($color_gray, .3);
		}

	}

	.contents {
		line-height: 2;

		& > :last-child {
			margin-bottom: 0;
		}

	}

	.slides {
		display: block;
		position: relative;
	}

	.slider {
		display: block;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		font-size: 0;
		line-height: 0;
		margin: 0 -15px;
		z-index: 2;
	}

	.slide {
		display: inline-flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: stretch;
		width: 33.33%;
		box-sizing: border-box;
		font-size: 16px;
		padding: 0 15px;
		line-height: 2;
		white-space: normal;
	}

	.hotel {
		display: block;
		position: relative;
		padding: 45px 50px;
		border: 1px solid #c7cfc4;
		flex-grow: 1;
	}

	.name {
		display: flex;
		justify-content: space-between;
		font-family: $font_playfair;
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 0.5px;
		line-height: 30px;
		margin-bottom: 15px;

		&:after {
			content: '';
			display: block;
			width: 18px;
			height: 14px;
			background: url(../images/ico_arrow_black.svg) 0 0 no-repeat;
			flex-grow: 0;
			flex-shrink: 0;
			margin: 10px 0 0 10px;
			transition: 0.3s;
		}

		&:hover {
			color: $color_pink;

			&:after {
				background-image: url(../images/ico_arrow_hover.svg);
			}

		}

	}

	.text {
		display: block;
		position: relative;
	}

	.rating {
		display: flex;
		align-items: center;
		margin-top: 20px;

		span {
			display: block;
			width: 15px;
			height: 15px;
			margin-right: 3px;
			background: url(../images/ico_star.svg) 50% 50% no-repeat;

			&.active {
				background-image: url(../images/ico_star_active.svg);
			}

		}

	}

	.navigation {
		display: flex;
		justify-content: flex-end;
	}

	.arrow_left, .arrow_right {
		display: block;
		width: 60px;
		height: 50px;
		background: rgba(#c7cfc4, .3) url(../images/ico_arrow_green.svg) 50% 50% no-repeat;
		cursor: pointer;
		transition: 0.3s;

		&:hover {
			background-color: $background_pink;
		}

	}

	.arrow_right {
		transform: rotate(180deg);
	}


	/* Animation */

	.contents {
		animation-name: fadeInRight;
		animation-delay: 0.75s;
	}

	.slide {
		animation-name: fadeIn;

		&:nth-child(2) {
			animation-delay: 0.25s;
		}

		&:nth-child(3) {
			animation-delay: 0.5s;
		}

	}

	@include desktop_small {

		.slider {
			margin: 0 -10px;
		}

		.slide {
			width: 50%;
			padding: 0 10px;
		}

		.hotel {
			padding: 25px 30px;
		}

		.heading {
			width: auto;
			font-size: 18px;
			line-height: 1.5;
			margin-right: 50px;
		}

	}

	@include tablet_large {

		.wrapper {
			flex-direction: column;
			align-items: center;
		}

		.heading {
			margin-right: 0;
		}

	}

	@include tablet_small {

		.slide {
			width: 100%;
		}



		.navigation {
			justify-content: center;
		}

		.arrow_left, .arrow_right {
			width: 40px;
			height: 35px;
		}

	}

	@include phone_large {

		.wrapper {
			margin-bottom: 30px;
		}

	}

}