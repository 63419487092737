.event_box {

	@extend %block;

	.items {
		display: flex;
		margin: 0 -80px;

		@extend %narrow;

	}

	.item {
		width: 50%;
		padding: 0 80px;
		box-sizing: border-box;
		animation-name: fadeInLeft;

		&:nth-child(2n) {

			.number, .caption, .title, .text {
				animation-name: fadeInRight;
			}

			.number {
				animation-delay: 1s;
			}

			.caption {
				animation-delay: 1.25s;
			}

			.title {
				animation-delay: 1.5s;
			}

			.text {
				animation-delay: 1.75s;
			}

		}

	}

	.text {
		line-height: 2;
		animation-delay: 0.75s;
		animation-name: fadeInLeft;
	}

	@include desktop_small {

		.items {
			margin: 0 -20px;
		}

		.item {
			padding: 0 20px;
		}

	}

	@include tablet_large {

		.items {
			flex-direction: column;
			margin: 0;
		}

		.item {
			width: 100%;
			padding: 0;
			margin-bottom: 50px;

			&:last-child {
				margin-bottom: 0;
			}

		}


	}

	@include tablet_small {

		.item {
			margin-bottom: 30px;
		}

	}

}